import React, {useEffect} from 'react';
import PortalRoutes from "./PortalRoutes";
import {PortalLinks} from "./PortalLinks";

export const Portal = ({history}) => {
    useEffect(() => {
        if (!localStorage.getItem('authKey') || localStorage.getItem('authKey') === '') {
            history.replace('/logout');
        }
    });
    return (
        <div>
            <header>
                <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr', alignItems: 'center'}}>
                    <img src="/logo.png" alt="PushPro" width="80" height="auto"/>
                    <h1>PushPro Admin Portal</h1>
                </div>
            </header>
            <div className="menu"><PortalLinks/></div>
            <div className="content">
                <PortalRoutes/>
            </div>
        </div>
    )
};