import React from 'react';
import { Link } from 'react-router-dom';

export const ToDetailFormatter = ({ context, value }) => (
  <Link to={`/portal/${context}/${value}`}>
    <div style={{ display: 'flex', alingItems: 'center' }}>
      Edit
    </div>
  </Link>
);
