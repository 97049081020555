import React from 'react';
import { Route, Switch } from "react-router-dom";
import {Sites} from "./Sites/Sites";
import {SiteDetail} from "./Sites/SiteDetail";
import {Accounts} from "./Accounts/Accounts";
import {AccountDetail} from "./Accounts/AccountDetail";
import {Dashboard} from "./Dashboard";
import {Trials} from "./Trials/Trials";

const Routes = () => (
<Switch>
    <Route exact path="/portal" component={Dashboard} />
    <Route exact path="/portal/sites" component={Sites} />
    <Route exact path="/portal/sites/:siteId" component={SiteDetail} />
    <Route exact path="/portal/accounts" component={Accounts} />
    <Route exact path="/portal/trials" component={Trials} />
    <Route exact path="/portal/accounts/:accountID" component={AccountDetail} />
</Switch>
);

export default Routes;
