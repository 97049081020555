import React from 'react';
import apiService from '../../../services/apiService';

export const ActivateDomainFormatter = ({value}) => {
    const getStatusToUpdate = currentStatus => {
        if (currentStatus === 'Active') {
            return 'in_active';
        }
        return 'active';
    };
    const changeSiteStatus = () => {
        apiService.put(`site/${value.site_uuid}`, {status: getStatusToUpdate(value.status)})
            .then(() => window.location.reload())
            .catch(e => alert(`Could not change site status. Error: ${e}`))
    };

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <input
                type="checkbox"
                checked={value.status === 'Active'}
                onChange={() => changeSiteStatus()}
            />
        </div>
    );
};
