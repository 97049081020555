import axios from "axios";

const getToken = () => localStorage.getItem('authKey');
const apiService = axios.create({
    baseURL: 'https://dev.pushpro.io/api/admin/'
});

apiService.interceptors.request.use(
    config => {
        config.headers['Authorization'] = 'Bearer ' + getToken();
        return config
    },
    error => {
        Promise.reject(error)
    }
);

export default apiService;