export const initialState = {
  filters: []
};

export const SearchReducer = (state, action) => {
  const UPDATE_ACCOUNT_FILTERS = 'UPDATE_ACCOUNT_FILTERS';

  switch (action.type) {
    case UPDATE_ACCOUNT_FILTERS:
      // Empty value, remove filter from array
      if (action.payload.value === '') { return { ...state, filters: state.filters.filter(e => e.name !== action.payload.name) }}
      // Empty filters array, push new filter to array
      if (state.filters.length < 1) { return { ...state, filters: [action.payload]} }
      // Existing filter in array, replace filter in array
      if (state.filters.some(e => e.name === action.payload.name)) {
        return {
          ...state, filters: state.filters.map(filter => {
            if (filter.name === action.payload.name) {
              return {...filter, ...action.payload}
            }
            return filter;
          })
        };
      }
      // Add new filter to array
      return { ...state, filters: [...state.filters, action.payload] };
    default:
      return initialState;
  }
};
