import React, {useState} from 'react';
import Modal from "../../Components/Modal/Modal";
import {Field, Form, Formik} from "formik";
import Select from 'react-select';

export const Trials = () => {
    const [showTrialModal, setShowTrialModal] = useState(false);

    const options = [
        {value: 'active', label: 'Active'},
        {value: 'inactive', label: 'Inactive'}
    ];
    return (
        <div>
            <h3>Trials</h3>
            <button onClick={() => setShowTrialModal(true)}>Add new Trial code</button>
            <Modal show={showTrialModal} onClose={() => setShowTrialModal(false)}>
                <Formik initialValues={{code: '', allowedUsage: '', used: '', status: ''}}
                        onSubmit={values => console.log(values)}
                        render={form => (
                            <Form>
                                <div className="formField">
                                    <label htmlFor="code">Trial code</label>
                                    <Field name="code" style={{width: 'auto'}}/>
                                </div>
                                <div className="formField">
                                    <label htmlFor="allowedUsage">Allowed Usage</label>
                                    <Field type="number" name="allowedUsage" style={{width: 'auto'}}/>
                                </div>
                                <div className="formField">
                                    <label htmlFor="status">Status</label>
                                    <Field name="status" render={({form, field}) => (
                                        <Select options={options}
                                                onChange={selected => form.setFieldValue(field.name, selected.value)}/>
                                    )}/>
                                </div>
                                <button type="submit" disabled={form.isSubmitting}>Add new Trial code</button>
                            </Form>
                        )}/>
            </Modal>
        </div>
    )
};
