import React, {useEffect, useState} from 'react';
import apiService from '../services/apiService';
import * as shortid from "shortid";
import {Link} from "react-router-dom";
import {Loader} from "../Components/Loader/Loader";

export const Dashboard = () => {
    const [accounts, setAccounts] = useState(null);
    const [sites, setSites] = useState(null);
    const [loading, setLoading] = useState(false);
    const [animate, setAnimate] = useState(false);
    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const {data: accountData} = await apiService.post('account/list', {pagination: {offset: 1, limit: 5}});
            const {data: siteData} = await apiService.post('site/list', {pagination: {offset: 1, limit: 5}});
            setAccounts(accountData);
            setSites(siteData);
            setLoading(false);
        };
        fetchData();
    }, []);
    const renderAccountList = () => {
        if (!accounts || accounts.length < 1) {
            return <div>No results to show</div>
        }
        return <ul className="list">{accounts.map(account => <li key={shortid.generate()}>
            <div style={{fontWeight: 'bold'}}>{account.name}</div>
            <div>{account.account_uuid}</div>
        </li>)}</ul>
    };
    const renderSiteList = () => {
        if (!sites || sites.length < 1) {
            return <div>No results to show</div>
        }
        return <ul className="list">{sites.map(site => <li key={shortid.generate()}>
            <div style={{fontWeight: 'bold'}}>{site.name}</div>
            <div>{site.site_uuid}</div>
        </li>)}</ul>
    };
    if (loading) {
        return <Loader/>
    }
    return (
        <div>
            <div style={{marginBottom: '10px'}}>Welcome to the Admin portal!</div>
            <img className={`banana ${animate ? 'animate' : ''}`}
                 src="https://media.tenor.com/images/5d4791abbfa98823cdbcd82b7ece2ced/tenor.gif" alt="dancing"
                 onClick={() => {
                     setAnimate(true);
                     window.setTimeout(() => {
                         setAnimate(false)
                     }, 400)
                 }}/>
            <div className="gridContainer">
                <div className="item">
                    <h3>Accounts</h3>
                    {renderAccountList()}
                    <Link to="/portal/accounts">
                        <button>See full accounts list</button>
                    </Link>
                </div>
                <div className="item">
                    <h3>Sites</h3>
                    {renderSiteList()}
                    <Link to="/portal/sites">
                        <button>See full sites list</button>
                    </Link>
                </div>
            </div>
        </div>
    )
};
