import React, {useEffect, useState} from 'react';
import DataTable from "../../Components/DataTable/DataTable";
import apiService from "../../services/apiService";
import {
    ActivateDomainFormatter,
    CustomImageFormatter
} from "../../Components/Formatters/Domains";
import {ToDetailFormatter} from "../../Components/Formatters/ToDetailFormatter";
import {DeleteFormatter} from "../../Components/Formatters/DeleteFormatter";
import {Loader} from "../../Components/Loader/Loader";

export const Sites = () => {
    const [sites, setSites] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const {data} = await apiService.post('site/list', {pagination: {offset: currentPage, limit: 15}});
            setSites(data);
            setLoading(false);
        };
        fetchData();
    }, [currentPage]);
    const columns = [
        {
            name: 'Active',
            key: '.',
            formatter: ActivateDomainFormatter
        },
        {
            name: 'Url',
            key: 'url'
        },
        {
            name: 'Custom Icon',
            key: 'image_url',
            formatter: CustomImageFormatter
        },
        {
            name: 'Edit',
            key: 'site_uuid',
            formatter: ToDetailFormatter,
            extraProps: {context: 'sites'}
        },
        {
            name: 'Delete',
            key: 'site_uuid',
            formatter: DeleteFormatter,
            extraProps: {context: 'site'}
        }
    ];
    if (loading) {
        return <Loader/>
    }
    return (
        <div>
            <h3>Sites</h3>
            <DataTable data={sites} columns={columns} setPage={page => setCurrentPage(page)} currentPage={currentPage}/>
        </div>
    )
};
