import React from 'react';
import {XCircle} from "react-feather";

const Modal = ({children, show, onClose}) => {
    if (!show) {
        return null;
    }

    const wrapperStyle = {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        padding: 50
    };

    // The gray background
    const backdropStyle = {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.3)',
        padding: 50,
        zIndex: 3
    };

    // The modal "window"
    const modalStyle = {
        position: 'relative',
        backgroundColor: '#fff',
        borderRadius: 5,
        maxWidth: 500,
        minHeight: 100,
        margin: '0 auto',
        padding: '50px 30px 30px 30px',
        zIndex: 10
    };

    const closeBtnStyle = {
        position: 'absolute',
        right: 10,
        top: 10,
        cursor: 'pointer'
    };

    return (
        <div style={ wrapperStyle }>
            <div className="backdrop" style={backdropStyle} onClick={onClose}/>
            <div className="modal" style={modalStyle}>
                <div style={closeBtnStyle} onClick={onClose}><XCircle/></div>
                {children}
            </div>
        </div>
    )
};

export default Modal;