import React, {useState, useContext} from 'react';
import Modal from "../Modal/Modal";
import {Edit} from "react-feather";
import Select from 'react-select';
import {Field, Form, Formik} from "formik";
import apiService from "../../services/apiService";
import {SearchContext} from "../../Private/Accounts/SearchContext/SearchContext";
import {getPlanName, planOptions} from "../../services/plans";

const EditPlanFormatter = ({value}) => {
    const [showModal, setShowModal] = useState(false);
    const {dispatch} = useContext(SearchContext);
    const changePlan = type => {
        apiService.put(`/account/${value.account_uuid}`, {type}).then(e => dispatch({
            type: 'UPDATE_SEARCH',
            payload: {}
        }))
    };
    const planName = getPlanName(value.type.toLowerCase());

    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                {planName}
                <span onClick={() => setShowModal(true)} style={{cursor: 'pointer', marginLeft: 5}}><Edit
                    size={16}/></span>
            </div>
            <Modal show={showModal} onClose={() => setShowModal(false)}>
                <h3>Edit Plan for Stripe id: {value.payment_customer_id}</h3>
                <div style={{marginBottom: 10}}>Current plan is { planName }</div>
                <Formik initialValues={{ type: value.type.toLowerCase() }} onSubmit={values => {
                    console.log(values);
                    changePlan(values.type)
                }}
                        render={form => (
                            <Form>
                                <div className="formField">
                                    <label htmlFor="type" style={{marginRight: 5}}>Change plan to:</label>
                                    <Field name="type" render={({form, field}) => (
                                        <Select options={planOptions} name="type"
                                                defaultValue={planOptions.find(e => e.value === field.value)}
                                                onChange={selected => form.setFieldValue(field.name, selected.value)}/>
                                    )} />
                                </div>
                                <div style={{marginTop: 10}}>
                                    <button disabled={form.isSubmitting} type="submit">Change plan</button>
                                </div>
                            </Form>
                        )}/>
            </Modal>
        </div>
    )
};

export default EditPlanFormatter;