import React, {useReducer} from 'react';
import {SearchProvider} from "./SearchContext/SearchContext";
import {initialState, SearchReducer} from "./SearchContext/SearchReducer";
import AccountList from "./AccountList";
import SearchForm from "./SearchForm";

export const Accounts = () => {
    const [search, dispatch] = useReducer(SearchReducer, initialState);

    return (
        <SearchProvider search={search} dispatch={dispatch}>
            <div>
                <h3>Accounts</h3>
                <SearchForm key='accountSearchForm' />
                <AccountList />
            </div>
        </SearchProvider>
    )
};
