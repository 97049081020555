import React from 'react';
import { Link } from 'react-router-dom';

export const PortalLinks = () => (
    <ul>
        <li><Link to="/portal">Home</Link></li>
        <li><Link to="/portal/sites">Sites</Link></li>
        <li><Link to="/portal/accounts">Accounts</Link></li>
        <li><Link to="/portal/trials">Trial codes</Link></li>
        <li><Link to="/logout">Logout</Link></li>
    </ul>
);