import React from 'react';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import apiService from "./services/apiService";

const authenticate = (formValues, setSubmitting, resetForm, history) => {
    apiService.get(`login?username=${formValues.username}&password=${formValues.password}`)
        .then(result => {
            if (result.status === 200 && result.data.jwt) {
                localStorage.setItem('authKey', result.data.jwt);
                history.push('/portal')
            } else {
                throw Error(`Request rejected with status ${result.status}`);
            }
        })
        .catch(error => {
            console.log('error');
            console.warn(error);
            alert('Failed to login');
            resetForm();
            setSubmitting(false);
        });
};

const SignInSchema = Yup.object().shape({
    username: Yup.string()
        .min(2, 'Username is too short!')
        .max(50, 'Username is too long!')
        .required('Username is required'),
    password: Yup.string()
        .min(2, 'Password is too short!')
        .max(50, 'Password is too long!')
        .required('Password is required')
});

export const Login = ({history}) => (
    <div className="login">
        <h1>Hold on Speedy Gonzales!</h1>
        <p>You have to login first to access this portal</p>
        <img
            src="https://previews.123rf.com/images/shakzu/shakzu1404/shakzu140400085/27714062-male-caucasian-police-officer-in-blue-cop-uniform-holds-up-hand-in-stop-gesture-on-white-background.jpg"
            alt="stop" title="please bend over and cough" width={200}/>
        <Formik
            initialValues={{username: '', password: ''}}
            validationSchema={SignInSchema}
            onSubmit={(formValues, {setSubmitting, resetForm}) => {
                authenticate(formValues, setSubmitting, resetForm, history)
            }}
        >{({isSubmitting, isValid}) => (
            <Form>
                <div>
                    <Field type="text" name="username" placeholder="Username" />
                    <ErrorMessage name="username" component="div" className="errorMsg" />
                </div>
                <div>
                    <Field type="password" name="password" placeholder="Password" />
                    <ErrorMessage name="password" component="div" className="errorMsg" />
                </div>
                <button type="submit" disabled={isSubmitting || !isValid}>
                    Submit
                </button>
            </Form>
        )}
        </Formik>
    </div>
);
