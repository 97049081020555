import React, {useEffect, useState} from 'react';
import apiService from "../../services/apiService";
import {Field, Form, Formik} from "formik";

const saveSite = (formValues, siteId, setSubmitting, resetForm, history) => {
    const { status } = formValues;
    formValues.status = (status === 'Active') ? 'active' : 'in_active';
    apiService.put(`site/${siteId}`, {...formValues })
        .then(res => {
            history.push('/portal/sites')
        })
        .catch(e => {
            console.log(e);
            resetForm();
            setSubmitting(false)
        })
};

export const SiteDetail = ({match, history}) => {
    const [siteDetails, setSiteDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const {data} = await apiService.get(`site/${match.params.siteId}`);
            setSiteDetails(data);
            setLoading(false);
        };
        fetchData();
    }, [match.params.siteId]);
    if (loading || !siteDetails) {
        return <div>Loading...</div>
    }
    const getStatusToUpdate = currentStatus => {
        if (currentStatus === 'Active') {
            return 'in_active';
        }
        return 'active';
    };
    return (
        <div>
            <h3>Site detail: {siteDetails.site.site_uuid}</h3>
            <Formik
                initialValues={siteDetails.site}
                onSubmit={(formValues, {setSubmitting, resetForm}) => {
                    saveSite(formValues, match.params.siteId, setSubmitting, resetForm, history)
                }}
                isInitialValid={true}
            >{form => (
                <Form>
                    <div className="formField">
                        <label>Site status:</label>
                        <Field name="status">
                            {({ field }) => (
                        <input
                            type="checkbox"
                            checked={field.value === 'Active'}
                            onChange={() => {form.setFieldValue(field.name, getStatusToUpdate(field.value))}}
                        />
                            )}
                        </Field>
                    </div>
                    <div className="formField">
                        <label>Site name:</label>
                        <Field type="text" name="name"/>
                    </div>
                    <div className="formField">
                        <label>Site url:</label>
                        <Field type="text" name="url"/>
                    </div>
                    <div className="formField">
                        <div><label>Site image:</label><img src={siteDetails.site.image_url} width="40" style={{ height: 'auto'}} alt="Site icon" /></div>
                        <Field type="text" name="image_url"/>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <button type="submit" disabled={form.isSubmitting || !form.isValid}>
                            Submit
                        </button>
                    </div>
                </Form>
            )}
            </Formik>
        </div>
    )
};
