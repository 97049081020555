import React from 'react';
import apiService from "../../services/apiService";
import {Trash} from "react-feather";

export const DeleteFormatter = ({ context, value }) => {
    const deleteItem = () => {
        if(window.confirm("Are you sure you want to delete?")){
            apiService.delete(`${context}/${value}`)
                .then(res => {console.log('Removed');window.location.reload()})
                .catch(e => alert(`Could not remove. Error: ${e}`))
        }
    };
    return (
        <div style={{cursor: 'pointer', textAlign: 'center' }} onClick={() => deleteItem()}>
            <Trash />
        </div>
    )
};
