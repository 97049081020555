export const planOptions = [
    {value: 'unknown', label: 'Unknown'},
    {value: 'free', label: 'Free'},
    {value: 'bronze', label: 'Starting'},
    {value: 'silver', label: 'Growing'},
    {value: 'gold', label: 'Booming'}
];

export const getPlanName = value => {
    return planOptions.find(e => e.value === value.toLowerCase()).label
};