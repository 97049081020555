import React from 'react';
import { Route, Switch } from "react-router-dom";
import {Login} from "./Login";
import {Portal} from "./Private/Portal";
import {Logout} from "./Private/Logout";

const Routes = () => (
<Switch>
    <Route exact path="/" component={Login} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/logout" component={Logout} />
    <Route path="/portal" component={Portal}/>
</Switch>
);

export default Routes;
