import React from 'react';

const Pagination = ({ currentPage, setPage, lastPage, noNext }) => (
  <ul className="pager">
    <li className="previous">
      <button disabled={currentPage <= 1} onClick={() => setPage(currentPage - 1)}>
        &lt;&nbsp;
        Previous
      </button>
    </li>
    {currentPage >= 3 && (
      <li>
        <div onClick={() => setPage(currentPage - 2)}>{currentPage - 2}</div>
      </li>
    )}
    {currentPage >= 2 && (
      <li>
        <div onClick={() => setPage(currentPage - 1)}>{currentPage - 1}</div>
      </li>
    )}
    <li className="current">{currentPage}</li>
    {currentPage + 1 <= lastPage && (
      <li>
        <div onClick={() => setPage(currentPage + 1)}>{currentPage + 1}</div>
      </li>
    )}
    {currentPage + 2 <= lastPage && (
      <li>
        <div onClick={() => setPage(currentPage + 2)}>{currentPage + 2}</div>
      </li>
    )}
    <li className="next">
      <button disabled={noNext} onClick={() => setPage(currentPage + 1)}>
        Next
        &nbsp;&gt;
      </button>
    </li>
  </ul>
);

export default Pagination;
