import React, {useState, useEffect, useContext} from 'react';
import DataTable from "../../Components/DataTable/DataTable";
import apiService from "../../services/apiService";
import {DeleteFormatter} from "../../Components/Formatters/DeleteFormatter";
import {SearchContext} from "./SearchContext/SearchContext";
import {Loader} from "../../Components/Loader/Loader";
import EditPlanFormatter from "../../Components/Formatters/EditPlanFormatter";

const AccountList = () => {
    const [accounts, setAccounts] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const { search } = useContext(SearchContext);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const {data} = await apiService.post('account/list', {
                pagination: {offset: currentPage, limit: 15},
                ...(search)
            });
            setAccounts(data);
            setLoading(false);
        };
        fetchData();
    }, [currentPage, search]);

    const columns = [
        {
            name: 'Status',
            key: 'status'
        },
        {
            name: 'Email',
            key: 'email'
        },
        {
            name: 'Account ID',
            key: 'account_uuid'
        },
        {
            name: 'Stripe id',
            key: 'payment_customer_id'
        },
        {
            name: 'Plan',
            key: '.',
            formatter: EditPlanFormatter
        },
        {
            name: 'Delete',
            key: 'account_uuid',
            extraProps: { context: 'account' },
            formatter: DeleteFormatter
        }
    ];

    if (loading) {
        return (
            <Loader/>
        )
    }
    return (
        <div>
            <DataTable data={accounts} columns={columns} currentPage={currentPage}
                       setPage={page => setCurrentPage(page)}/>
        </div>
    )
};

export default AccountList;