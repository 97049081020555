import React from 'react';
import shortid from 'shortid';
import Pagination from './Pagination';

const DataTable = props => {
  const resolvePath = (object, path, defaultValue) =>
    path.split('.').reduce((newObj, p) => (newObj ? newObj[p] : defaultValue), object);

  const lastPage = Math.ceil(props.totalResults / props.pageSize);
  const renderedHeader = () => (
    <thead>
      <tr>
        {props.columns.map(column => (
          <th key={shortid.generate()}>{column.name}</th>
        ))}
      </tr>
    </thead>
  );
  const renderedBody = () => {
    if (!props.data || (props.data.length < 1 && props.currentPage === 1)) {
      return (
        <tbody>
          <tr>
            <td colSpan={props.columns.length}>
                {props.nullResults || 'No results to show'}
            </td>
          </tr>
        </tbody>
      );
    }
    if (props.data.length < 1 && props.currentPage !== 1) {
      return (
        <tbody>
          <tr>
            <td colSpan={props.columns.length}>
              {props.noResults || 'No results found'}
            </td>
          </tr>
        </tbody>
      );
    }
    return (
      <tbody>
        {props.data.map(dataItem => (
          <tr key={shortid.generate()}>
            {props.columns.map(column => {
              if (column.formatter) {
                return (
                  <td key={shortid.generate()}>
                    {React.createElement(column.formatter, {
                      ...column.extraProps,
                      value: resolvePath(dataItem, column.key, dataItem)
                    })}
                  </td>
                );
              }
              return <td key={shortid.generate()}>{resolvePath(dataItem, column.key)}</td>;
            })}
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div>
      <div className="table-responsive">
        <table className="table">
          {renderedHeader()}
          {renderedBody()}
        </table>
      </div>
      <Pagination
        setPage={page => props.setPage(page)}
        currentPage={props.currentPage}
        lastPage={lastPage}
        noNext={
          !props.data || props.data.length < 1 || props.data.length < props.pageSize || props.currentPage === lastPage
        }
      />
    </div>
  );
};

export default DataTable;
