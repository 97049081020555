import React, {useContext} from 'react';
import {SearchContext} from "./SearchContext/SearchContext";
import {findFilterValue} from "../../services/filterHelpers";

const SearchForm = () => {
    const {search, dispatch} = useContext(SearchContext);

    return (
        <fieldset style={{ marginBottom: '20px', borderColor: '#eeeeee', borderStyle: 'solid', padding: '20px' }}>
            <legend style={{ fontWeight: 'bold', padding: '0 20px', backgroundColor: '#fff' }}>Filter results:</legend>
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '30px', alignItems: 'center'}}>
            <div className="formField">
                <label>Account ID:</label>
                <input type="text" onChange={e => dispatch({type: 'UPDATE_ACCOUNT_FILTERS', payload: { name: 'uuid', type: 'like', value: e.target.value}})}
                       value={findFilterValue(search.filters, 'uuid')} name="uuid"/>
            </div>
            <div className="formField">
                <label>Email:</label>
                <input type="text" onChange={e => dispatch({type: 'UPDATE_ACCOUNT_FILTERS', payload: { name: 'email', type: 'like', value: e.target.value}})}
                       value={findFilterValue(search.filters, 'email')} name="email"/>
            </div>
            {/*<div className="formField">*/}
            {/*    <label>Stripe ID:</label>*/}
            {/*    <input type="text"*/}
            {/*           onChange={e => dispatch({*/}
            {/*               type: 'UPDATE_ACCOUNT_FILTERS',*/}
            {/*               payload: { name: 'payment_customer_id', type: 'like', value: e.target.value}*/}
            {/*           })}*/}
            {/*           value={findFilterValue(search.filters, 'payment_customer_id')} name="payment_customer_id"/>*/}
            {/*</div>*/}
        </div>
        </fieldset>
    )
};

export default SearchForm;